.search-wrapper,
.search-wrapper-home {
    width: px2em(300);

    .input-group {
        border: 0;
        background-color: white;
        margin: 0;

        .input-group-prepend {
            margin: auto;
            margin-left: px2em(8);
            color: $primary;
        }

        input {
            padding: px2em(2) px2em(8);
            padding-right: px2em(40);
        }

        .ladda-button[disabled] {
            padding-top: px2em(10) !important;
        }
    }

    .autocomplete-menu {
        width: px2em(300);
    }
}

.search-wrapper {
    width: px2em(180);
    margin-right: px2em(24);

    .input-group {
        background-color: $main-light-40;
        height: px2em(32);

        .input-group-prepend svg {
            font-size: px2em(20);
        }

        .input-group-append svg {
            margin-top: 0 !important;
        }

        input {
            font-size: px2em(16);
            height: px2em(32);
        }

        .ladda-button[disabled] {
            padding-top: px2em(6) !important;
        }

        .autocomplete-menu {
            width: calc(100% + #{px2em(28)});
        }
    }

    .autocomplete-menu {
        width: px2em(180);
    }
}

.search-wrapper-home {
    position: absolute;
    top: calc(50% - #{px2em(30)});
    left: 50%;
    transform: translate(-50%, -50%);

    label {
        font-size: px2em(24);
        margin-bottom: px2em(24);
        color: $primary;
        font-weight: bold;
        text-align: center;
        padding: px2em(15);
        margin: 0 auto;
    }

    .input-group {
        box-shadow: $shadow;
    }
}

/* IE11 --------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .search-wrapper {
    .input-group .input-group-prepend {
        margin-top: px2em(6);
    }
}
    .search-wrapper-home {
        .input-group .input-group-prepend {
            margin-top: px2em(8);
        }
    }
}
