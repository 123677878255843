.toast-container {
    top: px2em(24);
    right: px2em(24);

    .Toastify__toast--success {
        background: $primary;
        opacity: 1;
    }

    .Toastify__toast--error {
        background: $danger;
        opacity: 1;
    }

    .Toastify__close-button {
        opacity: 1;
        align-self: center;
        font-size: px2em(22);

        &:focus {
            outline: none;
        }
    }

    .toast {
        border-radius: px2em(4);
        box-shadow: $shadow;
        padding: px2em(18);

        .toast-body {}
    }
}
