form {
    padding: px2em(16) px2em(24) !important;
    border-radius: px2em(4);

    label {
        color: $main;
        font-weight: bold;
        font-size: px2em(14);
        letter-spacing: $letter-spacing;
        margin-bottom: px2em(8) !important;
        text-transform: uppercase;
    }

    .form-control {
        border: px2em(2) solid $secondary;
        color: $main;
        font-size: px2em(18);
        height: px2em(40);
    }

    .form-control:focus {
        border: px2em(2) solid $secondary;
        box-shadow: none;
    }

    input.is-invalid,
    .invalid-group input {
        border-color: $danger !important;
    }

    .invalid {
        color: $danger !important;
        font-size: px2em(12);
        position: absolute;
    }

    .invalid.hidden {
        display: none;
    }

    .half {
        border: px2em(2) solid $main-light-20;
        background-color: white;
    }

    .half.editing,
    .half:focus {
        border: px2em(2) solid $secondary;
    }

    .form-section {
        margin-bottom: px2em(40);
        line-height: px2em(16);

        &.remove-margin {
            margin-bottom: 0;
        }

        .header {
            border-bottom: px2em(2) solid $main-light-40;
            padding-bottom: px2em(8);
            margin-bottom: px2em(12);
            position: relative;

            h4 {
                font-weight: bold;
                font-size: px2em(22);
                margin: 0;
            }
        }

        p {
            margin: 0;
            font-size: px2em(20);
            line-height: px2em(22);
        }

        .text-muted {
            color: $main-light-20 !important;
        }

        .custom-property {
            position: absolute;
            right: px2em(20);
            top: px2em(60);
            font-size: px2em(18);

            svg {
                font-size: px2em(18);
                margin-right: px2em(8);
                margin-bottom: px2em(2);
            }

            label {
                margin-right: px2em(5);
                font-size: px2em(16);
            }

            span {
                color: $main-light-20;
            }
        }

        .highlight {
            line-height: px2em(36);
            font-size: px2em(18);
            margin-bottom: px2em(32);

            div {
                border-left: px2em(4) solid $secondary;
                position: absolute;
                left: 0;
                height: px2em(36);
                z-index: 10;
            }
        }

        .select {
            span {
                line-height: px2em(24);
            }

            .dropdown-item {
                line-height: px2em(24);
            }
        }

        .autocomplete-menu div {
            line-height: px2em(24);
        }
    }

    .form-control.is-invalid:focus {
        box-shadow: none;
    }

    .form-group:last-child {
        margin: 0;
    }

    .form-buttons {
        padding-top: px2em(28);

        button {
            margin-left: px2em(24);
        }
    }
}
