@keyframes lds-cube {
    0% {
        -webkit-transform: scale(0.02);
        transform: scale(0.02);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes lds-cube {
    0% {
        -webkit-transform: scale(0.02);
        transform: scale(0.02);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.lds-cube {
    position: fixed;
    z-index: 999;
    height: px2em(32);
    width: px2em(32);
    margin: auto;
    top: px2em(70);
    left: px2em(250);
    bottom: 0;
    right: 0;
}

.lds-cube.full {
    top: 0;
    left: 0;
}

.lds-cube.toggled {
    left: px2em(60);
}

.lds-cube div {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 10px;
    left: 10px;
    background: $primary;
    -webkit-animation: lds-cube 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation: lds-cube 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.lds-cube div:nth-child(2) {
    top: 10px;
    left: 70px;
    background: $primary;
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.lds-cube div:nth-child(3) {
    top: 70px;
    left: 70px;
    background: $primary;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.lds-cube div:nth-child(4) {
    top: 70px;
    left: 10px;
    background: $primary;
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

.lds-cube {
    width: 120px !important;
    height: 120px !important;
    -webkit-transform: translate(-70px, -70px) scale(1) translate(70px, 70px);
    transform: translate(-70px, -70px) scale(1) translate(70px, 70px);
}
