.notifications {
  margin-right: px2em(24);

  .badge-notify {
    position: relative;
    top: px2em(-24);
    right: px2em(-8);
    font-size: px2em(8);
    line-height: px2em(10);
    padding: px2em(10) !important;
    background-color: $primary;
    color: white;
    padding: px2em(10) !important;
    width: auto;
  }

  .notification-button {
    font-size: px2em(24) !important;
    font-weight: bold;
  }

  .dropdown-menu {
    margin-top: px2em(48);
    width: auto;
    max-height: px2em(300);
    max-width: px2em(500);
    overflow-y: auto;

    .dropdown-item {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.unread {
        background-color: $main-light-40;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 px2em(6) rgba(0, 0, 0, 0.3);
      border-radius: px2em(10);
      background-color: $light-bg;
    }

    &::-webkit-scrollbar {
      width: px2em(12);
      background-color: $light-bg;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: px2em(10);
      -webkit-box-shadow: inset 0 0 px2em(4) rgba(0, 0, 0, 0.3);
      background-color: $main-light-40;
    }
  }
}
