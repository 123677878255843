.material-col {
    display: flex !important;
}

.material-preview {
    background-color: white;
    min-height: px2em(188);
    width: calc(100% - #{px2em(32)});
    padding: px2em(16);
    border-radius: px2em(4);
    margin: px2em(16) auto;
    cursor: pointer;
    border: 2px solid transparent !important;

    &.selected,
    &:hover {
        border: 2px solid $primary !important;

        .title {
            color: $primary;
        }

        svg {
            color: $primary !important;
        }
    }

    .card-body {
        text-align: center;
        overflow: visible;

        .title {
            font-size: px2em(18);
            margin-top: px2em(8);
        }

        .code {
            position: absolute;
            left: -2px;
            top: -2px;
            background-color: $primary;
            font-size: px2em(14);
            padding: px2em(4) px2em(8);
            border-top-left-radius: px2em(4);
            border-bottom-right-radius: px2em(4);
            color: white;
            z-index: 2;
            letter-spacing: 0.05em;
        }

        .img-box {
            width: 100%;
            position: relative;
            padding-bottom: 75%;
            top: 0;
            left: 0;
            right: 0;
        }

        .folder-box {
            width: 100%;
            padding-bottom: 75%;
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 70%;
                height: auto;
            }
        }
    }
}
