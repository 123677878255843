.badge {
    text-transform: uppercase;
    padding: px2em(8) px2em(14) !important;
    font-size: px2em(14);
    width: px2em(152);
}

span[class^="badge-outline-"] {
    background-color: transparent !important;
    letter-spacing: $letter-spacing !important;
}

.badge-outline-success {
    color: $success !important;
    border: px2em(2) solid $success !important;
}

.badge-outline-info {
    color: $info !important;
    border: px2em(2) solid $info !important;
}

.badge-outline-warning {
    color: $warning !important;
    border: px2em(2) solid $warning !important;
}

.badge-outline-danger {
    color: $danger !important;
    border: px2em(2) solid $danger !important;
}
