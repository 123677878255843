.sidebar-nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  box-shadow: $shadow;
  overflow: hidden;

  .sidebar-brand {
    margin-top: px2em(18);
    margin-bottom: px2em(60);
    text-align: center;

    svg {
      color: white;
      font-size: px2em(26);
      width: auto;
      cursor: pointer;
    }

    a {
      border: 0 !important;
      padding: 0;
      display: inline;

      .fade {
        display: inline;

        .logo {
          padding: 0;
          height: px2em(40);
          position: relative;
        }
      }
    }

    a:hover,
    a:active,
    a:focus {
      background: transparent !important;
      border: 0 !important;
    }
  }

  li {
    box-sizing: border-box;
    width: px2em(250);
    white-space: nowrap;
    overflow: hidden;

    a {
      color: $main;
      display: block;
      font-weight: bold;
      padding: px2em(10) px2em(48);
      text-decoration: none;
      font-size: px2em(18);
      margin-bottom: px2em(2);

      svg {
        padding-right: px2em(8);
        margin-bottom: px2em(1);
        font-size: px2em(22);
        height: px2em(18);

        path,
        circle {
          fill: $main;
        }
      }

      &:hover,
      &:active,
      &.active-link {
        color: $primary;

        svg path,
        svg circle {
          fill: $primary;
        }
      }

      &.disabled,
      &.disabled svg {
        color: $main-light-20;
        cursor: default;
      }
    }
  }

  .sub-menu {
    max-height: 0;
    width: px2em(250);
    padding-left: 0;
    background: rgba(255, 255, 255, 0.05);
    list-style: none;

    li {
      a {
        padding-left: px2em(88);

        &:hover,
        &:active,
        &.active-sub-link {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }

    &.open {
      max-height: px2em(100);
    }
  }
}

/* TOGGLED --------------------------------- */

#wrapper.toggled {
  .sidebar-nav {
    overflow: visible;

    .sidebar-brand svg {
      padding-left: px2em(10);
    }

    li {
      width: px2em(60);

      a {
        padding: px2em(10) px2em(12);
      }
    }

    .sub-menu {
      max-height: 0;
      position: absolute;
      left: px2em(68);
      box-shadow: $shadow;
      background-color: white;
      border-radius: px2em(4);

      li {
        max-height: 0;
        width: auto;

        a {
          padding-left: px2em(16);
          margin-bottom: 0;
          color: $main;

          &:hover,
          &:active,
          &.active-sub-link {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }

      &.open {
        max-height: px2em(100);

        li {
          max-height: unset;
        }
      }
    }
  }
}

/* TRANSITIONS --------------------------------- */

.sidebar-nav li a,
.sidebar-brand svg {
  -webkit-transition: padding 0.5s ease;
  -moz-transition: padding 0.5s ease;
  -o-transition: padding 0.5s ease;
  transition: padding 0.5s ease;
}

.sidebar-nav li {
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
}

.sub-menu.open,
.sub-menu {
  -webkit-transition: max-height 0.5s ease;
  -moz-transition: max-height 0.5s ease;
  -o-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
}

/* IE11 --------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .sidebar-nav {
    li a {
      padding: px2em(10) px2em(24);

      svg {
        width: px2em(40);
        padding: 0;
      }
    }

    .sub-menu li a {
      padding-left: px2em(92) !important;
    }
  }
}