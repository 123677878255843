.top-nav {
    background-color: white !important;
    position: fixed;
    top: 0;
    left: px2em(250);
    right: 0;
    height: px2em(60);
    z-index: 1000;
    box-shadow: $shadow;

    .form-inline {
        height: px2em(60);
        padding: px2em(12) px2em(16);
    }

    .right-nav {
        background-color: white;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
}
