/************************************
   imgAdaptive [22-05-2019]
*************************************/
.img_hide {
  opacity: 0 !important;
  width: 100% !important;
  height: !important;
  max-width: initial !important;
  min-width: initial !important;
  max-height: initial !important;
  min-height: initial !important;
}

/*
.high_height{
position:absolute;
right: 0;
width:100%;
height:auto;
top: 50%;
left:50%;
transform: translate(-50%,-50%);
}

.high_width{
position:absolute;
right: 0;
width:auto;
height:100%;
top: 50%;
left:50%;
transform: translate(-50%,-50%);
}
*/

.img-box {
  .high_width {
      position: absolute;
      right: 0;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }

  .high_height {
      position: absolute;
      right: 0;
      width: auto;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
}

.box_img_adaptive {
position: absolute;
width: 100%;
height: 100%;
top: 0;
left: 0;
}

/* spinner img ------------------------------- */
@-moz-keyframes throbber-loader {
  0% {
      background: #dde2e7;
  }

  10% {
      background: silver;
  }

  40% {
      background: #dde2e7;
  }
}

@-webkit-keyframes throbber-loader {
  0% {
      background: #dde2e7;
  }

  10% {
      background: silver;
  }

  40% {
      background: #dde2e7;
  }
}

@keyframes throbber-loader {
  0% {
      background: #dde2e7;
  }

  10% {
      background: silver;
  }

  40% {
      background: #dde2e7;
  }
}

/* :not(:required) hides these rules from IE9 and below */
.throbber-loader {
  -moz-animation: throbber-loader 2000ms 300ms infinite ease-out;
  -webkit-animation: throbber-loader 2000ms 300ms infinite ease-out;
  animation: throbber-loader 2000ms 300ms infinite ease-out;
  background: #dde2e7;
  display: inline-block;
  position: relative;
  text-indent: -9999px;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 0.6rem;
}

.throbber-loader:before,
.throbber-loader:after {
  background: #dde2e7;
  content: '\x200B';
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 0;
}

.throbber-loader:before {
  -moz-animation: throbber-loader 2000ms 150ms infinite ease-out;
  -webkit-animation: throbber-loader 2000ms 150ms infinite ease-out;
  animation: throbber-loader 2000ms 150ms infinite ease-out;
  left: -2.4em;
}

.throbber-loader:after {
  -moz-animation: throbber-loader 2000ms 450ms infinite ease-out;
  -webkit-animation: throbber-loader 2000ms 450ms infinite ease-out;
  animation: throbber-loader 2000ms 450ms infinite ease-out;
  right: -2.4em;
}

.spinner_box_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}