.materials-list {
    padding: px2em(16);

    .instructions {
        border-right: px2em(2) solid $main-light-40 !important;
        height: px2em(304);
        padding: 0 !important;
    }

    .materials {
        padding: 0 !important;

        .row {
            .material-preview {
                margin-bottom: px2em(32);
            }
        }

        &.special {
            background-color: $main-light-40;
            border-radius: px2em(4);
            padding-left: 0;

            .disclaimer {
                text-align: center;
                font-size: px2em(14);
                padding: px2em(32) !important;

                svg {
                    margin-right: px2em(8);
                    font-size: px2em(20);
                }
            }
        }
    }
}
