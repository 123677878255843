/* VARIABLES OVERRIDE */
@import "variables";

/* LADDA BUTTONS */
@import "~ladda/css/ladda.scss";

/* BOOTSTRAP */
@import "~bootstrap/scss/bootstrap";

/* MATERIAL-UI ICON */
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

/* TOASTIFY */
@import "~react-toastify/scss/main.scss";

/* FONT */
@import "fonts";

/* CUSTOM */
@import "custom";
@import "layout";

/* ELEMENTS */
@import "elements/badge";
@import "elements/images";
@import "elements/loading";
@import "elements/toast";

/* ELEMENTS INPUTS */
@import "elements/inputs/auto-complete";
@import "elements/inputs/buttons";
@import "elements/inputs/input-group";
@import "elements/inputs/inputs";
@import "elements/inputs/search";

/* ELEMENTS WRAPPERS */
@import "elements/wrappers/card";
@import "elements/wrappers/form";

/* MATERIALS */
@import "materials/materials-list";
@import "materials/material-preview";

/* NAVBAR */
@import "navbar/notifications-menu";
@import "navbar/path";
@import "navbar/side-nav";
@import "navbar/top-nav";
@import "navbar/user-menu";

/* PAGES */
@import "pages/packaging";
@import "pages/material-detail";

/* MEDIA QUERIES */
@import "mq";
