.material-detail {
  padding: 0 !important;
  overflow: hidden;

  .material-info {
    background-color: white;
    height: calc(100vh - #{px2em(96)});

    .material-info-header {
      background-color: $light-bg;
      padding: px2em(24);
      height: px2em(100);
      width: 100%;

      .code {
        background: $main;
        color: white;
        font-size: px2em(14);
        display: inline;
        padding: px2em(4) px2em(8);
        letter-spacing: 0.05em;
      }

      .title {
        color: $primary;
        font-size: px2em(20);
        font-weight: bold;
      }

      .btn {
        position: absolute;
        top: px2em(32);
        right: px2em(24);
        width: auto;

        .ladda-label {
          display: flex;
        }

        /* IE11 --------------------------------- */

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
          width: px2em(150);
          height: px2em(38);
          white-space: nowrap;

          svg {
            width: px2em(18);
          }
        }
      }
    }

    .material-info-body {
      padding: px2em(24);
      overflow-y: auto;

      .elements {
        background-color: $light-bg;
        margin-bottom: px2em(24);

        .element {
          padding: px2em(8) px2em(16);

          &:not(:last-child) {
            border-bottom: 1px solid $main-light-40;
          }

          label {
            margin-bottom: 0;
          }

          span {
            float: right;
          }
        }
      }
    }
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}
