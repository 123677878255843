html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: CustomFont, "Open Sans", "Helvetica Neue", sans-serif;
  color: $main;
  background-color: $light-bg;
}

/* MARGIN & PADDING --------------------------------- */
.row,
.col,
div[class^="col"],
.container,
.container-fluid {
  margin: 0;
  padding: 0 !important;
}

/* SHADOW --------------------------------- */

.shadow {
  box-shadow: $shadow !important;
}

.button-shadow {
  box-shadow: $button-shadow;
}

/* ALTRO --------------------------------- */

a,
a:hover {
  color: $primary;
}

button,
a,
a:hover {
  text-decoration: none !important;
}

p {
  margin-bottom: 0;
}

.overflow-visible {
  overflow: visible !important;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.bg-white {
  background-color: white;
}

.pull-right {
  justify-content: flex-end;
}

h3 {
  font-size: px2em(22);
  font-weight: bold;
}

iframe {
  border: 0;
}

/* COOKIESBANNER ---------------------------------- */

.cookie-message img {
  margin-right: 1em;
  max-width: 2.5em;
}

/* PAGE CONTAINER --------------------------------- */

.page-container {
  padding: px2em(16) !important;
  min-height: calc(100vh - #{px2em(60)});
  max-height: calc(100vh - #{px2em(60)});
  overflow-x: hidden;

  &.height-auto {
    min-height: unset;
  }

  .lds-cube {
    left: 0;
  }
}

/* HOMEPAGE ---------------------------------------- */

.homepage {
  background-image: url(../images/bg_home.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  #powered-by {
    position: absolute;
    bottom: px2em(16);
  }
}

/* POVERED BY -------------------------------------- */

#powered-by {
  background: white;
  margin-left: px2em(-18);
  margin-bottom: px2em(-16);
  margin-right: px2em(-16);
  padding: px2em(8) px2em(18);
  box-shadow: $shadow;
  width: 120%;
  font-size: px2em(14);
}

.material-detail #powered-by {
  margin-left: 0;
  position: absolute;
}
