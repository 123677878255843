.path {
  overflow: hidden;

  .breadcrumb {
    background-color: white;
    color: inherit;
    font-size: px2em(20);
    line-height: px2em(16);
    font-weight: bold;
    margin: 0;
    padding: 0;

    .breadcrumb-item {
      padding: 0;
      margin: auto 0;

      a {
        color: $main;

        &:hover {
          color: $primary;
        }
      }

      svg {
        width: px2em(18);
        height: px2em(18);
        vertical-align: initial;

        path {
          fill: $main;
        }

        &:hover path {
          fill: $primary;
        }
      }

      &:last-child a {
        color: $primary;
      }

      &:first-child a {
        color: $main;
      }
    }

    .breadcrumb-item+.breadcrumb-item::before {
      content: '';
    }
  }
}
