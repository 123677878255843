/* NORMAL ---------------------------------------- */

@font-face {
    font-family: 'CustomFont';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/IBMPlexSansRegular.eot");
    src: url("../fonts/IBMPlexSansRegular.eot") format("embedded-opentype"), url("../fonts/IBMPlexSansRegular.woff2") format("woff2"), url("../fonts/IBMPlexSansRegular.woff") format("woff"), url("../fonts/IBMPlexSansRegular.ttf") format("truetype");
}

/* BOLD ---------------------------------------- */

@font-face {
    font-family: 'CustomFont';
    font-style: bold;
    font-weight: 600;
    src: url("../fonts/IBMPlexSansMedium.eot");
    src: url("../fonts/IBMPlexSansMedium.eot") format("embedded-opentype"), url("../fonts/IBMPlexSansMedium.woff2") format("woff2"), url("../fonts/IBMPlexSansMedium.woff") format("woff"), url("../fonts/IBMPlexSansMedium.ttf") format("truetype");
}

/* LIGHT ---------------------------------------- */

@font-face {
    font-family: 'CustomFont';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/IBMPlexSansLight.eot");
    src: url("../fonts/IBMPlexSansLight.eot") format("embedded-opentype"), url("../fonts/IBMPlexSansLight.woff2") format("woff2"), url("../fonts/IBMPlexSansLight.woff") format("woff"), url("../fonts/IBMPlexSansLight.ttf") format("truetype");
}

/* ITALIC ---------------------------------------- */

@font-face {
    font-family: 'CustomFont';
    font-style: italic;
    src: url("../fonts/IBMPlexSansItalic.eot");
    src: url("../fonts/IBMPlexSansItalic.eot") format("embedded-opentype"), url("../fonts/IBMPlexSansItalic.woff2") format("woff2"), url("../fonts/IBMPlexSansItalic.woff") format("woff"), url("../fonts/IBMPlexSansItalic.ttf") format("truetype");
}
