input[role="combobox"] {
    width: 100%;
    margin: 0;
    font-size: px2em(16);
    background-color: transparent;
    z-index: 100;
    height: px2em(36.5);
}

.autocomplete-menu {
    background-color: white;
    max-height: px2em(128);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px px2em(12);
    padding-top: px2em(30);
    position: absolute;
    box-sizing: content-box;
    margin-top: px2em(-24);
    z-index: -100;
    width: 100%;
    right: 0;
    border-bottom-left-radius: px2em(12);
    border-bottom-right-radius: px2em(12);
    background-clip: padding-box;

    div:first-of-type {
        max-height: px2em(98);
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: px2em(10);
            background-color: $light-bg;
            z-index: 100;
        }

        & > * {
            padding: 0.15em 0.35em;
            padding-left: px2em(36);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
        }

        &::-webkit-scrollbar-track {
            border-radius: px2em(10);
            background-color: $light-bg;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: px2em(10);
            background-color: $main-light-40;
        }
    }
}
