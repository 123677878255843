.username {
    font-size: px2em(20);
    font-weight: bold;
}

.profile-button {
    background-color: $primary;
    margin-left: px2em(24);
    box-shadow: none;
    border-color: $primary;

    svg {
        font-size: px2em(22);
    }
}

.profile-button:hover,
.profile-button:focus,
.profile-button:active {
    background-color: $primary-sat-40 !important;
    box-shadow: none !important;
    outline: none !important;
    border-color: $primary !important;
}

.dropdown-menu {
    margin-top: px2em(56);
    padding: 0;
    border-radius: px2em(4);
    overflow: hidden;
    width: auto;
    transform: translate3d(0, 0, 0) !important;
    box-shadow: $shadow;
    left: auto !important;
    right: 0 !important;

    .dropdown-item {
        margin: 0;
        outline: none;
        color: $main;
        padding: px2em(16);
        font-size: px2em(18);
        background-color: white;
        width: auto;

        svg {
            position: absolute;
            bottom: px2em(14);
            right: px2em(14);
            font-size: px2em(20);
        }
    }

    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
        background-color: white;
        text-shadow: 0.3px 0px 0px $main;
        transition: 0.2s text-shadow;
        outline: none;
        cursor: pointer;
    }
}

.dropdown-menu.user-menu {
    button.dropdown-item {
        font-weight: bold;
        color: $primary;
    }

    .dropdown-item:last-child:hover,
    .dropdown-item:last-child:focus,
    .dropdown-item:last-child:active {
        color: $primary;
        cursor: pointer;
    }
}
