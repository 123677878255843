.packaging-crop-img-large {
  height: px2em(160);
  margin: px2em(-16);
}

.packaging-header {
  height: auto;
  max-height: px2em(128);
  padding: px2em(16);
  padding-left: px2em(80);
  width: calc(100vw - #{px2em(250)});
  background-color: white;
  position: relative;
  margin: px2em(16) px2em(-16);

  svg {
    height: px2em(24);
    width: px2em(24);
    position: absolute;
    left: px2em(24);
    top: px2em(24);
    cursor: pointer;

    &:hover path,
    &:hover polyline {
      stroke: $primary;
    }
  }

  .title {
    color: $primary;
    font-size: px2em(24);
  }

  .description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.packaging-materials {
  min-height: calc(100vh - #{px2em(357)});
  margin-bottom: px2em(16);
}
