// Breakpoints
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1366px;
$bp-xlarge: 1920px;
$bp-xxlarge: 2560px;

// Media Queries
$mq-xsmall: "(max-width: #{$bp-small})";
$mq-small: "(min-width: #{$bp-small})";
$mq-medium: "(min-width: #{$bp-medium})";
$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-xxlarge: "(min-width: #{$bp-xxlarge})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

html {
    @media #{$mq-xsmall} {
        font-size: $basepx - 3px;
    }

    @media #{$mq-small} {
        font-size: $basepx - 2px;
    }

    @media #{$mq-medium} {
        font-size: $basepx - 1px;
    }

    @media #{$mq-large} {
        font-size: $basepx;
    }

    @media #{$mq-xlarge} {
        font-size: $basepx;
    }

    @media #{$mq-xxlarge} {
        font-size: $basepx + 2px;
    }
}
